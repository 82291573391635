//------------------------------------------------------------------------------
import XXH from 'xxhashjs'
import Entity from 'Entity';
import SDK3DVerse_EntityRegistry from 'EntityRegistry';
import { glMatrix, vec3, quat } from 'gl-matrix';

//------------------------------------------------------------------------------
const Quaternion_Epsilon    = 0.0001;
const MinQuaternionLength   = 1 - Quaternion_Epsilon;
const MaxQuaternionLength   = 1 + Quaternion_Epsilon;

//------------------------------------------------------------------------------
/**
 * @ignore
 */
export default class SDK3DVerse_Utils
{
    //--------------------------------------------------------------------------
    static invalidUUID = "00000000-0000-0000-0000-000000000000";

    static identityTransform = Object.freeze(
    {
        position    : [0.0, 0.0, 0.0],
        orientation : [0.0, 0.0, 0.0, 1.0],
        scale       : [1.0, 1.0, 1.0]
    })

    //--------------------------------------------------------------------------
    static neutralDirection = vec3.fromValues(0.0, 0.0, 1.0)

    //--------------------------------------------------------------------------
    static neutralUp = vec3.fromValues(0.0, 1.0, 0.0)

    //--------------------------------------------------------------------------
    static clone(obj)
    {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj)
        {
            return obj;
        }

        // Handle Date
        if (obj instanceof Date)
        {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array)
        {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++)
            {
                copy[i] = SDK3DVerse_Utils.clone(obj[i]);
            }
            return copy;
        }

        // Prevent entity registry to be cloned
        if(obj instanceof SDK3DVerse_EntityRegistry)
        {
            return {};
        }

        // Prevent entity to be cloned
        if(obj instanceof Entity)
        {
            return {};
        }

        // Handle Object
        if (obj instanceof Object)
        {
            copy = {};
            for (var attr in obj)
            {
                if (obj.hasOwnProperty(attr))
                {
                    copy[attr] = SDK3DVerse_Utils.clone(obj[attr]);
                }
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    //--------------------------------------------------------------------------
    static fillMousePosBuffer(element, PosX, PosY, bufferSize = 8)
    {
        var MouseData       = new ArrayBuffer(bufferSize);
        var bufferWriter    = new DataView(MouseData);

        var offset = element.getClientRects()[0];
        var PositionX = (PosX - offset.left) / offset.width;
        var PositionY = (PosY - offset.top) / offset.height;

        bufferWriter.setFloat32(0, PositionX, true);
        bufferWriter.setFloat32(4, PositionY, true);

        return MouseData;
    }

    //--------------------------------------------------------------------------
    static fillPinchPosBuffer(element, Pos1X, Pos1Y, Pos2X, Pos2Y, Actual_Width, Actual_Height)
    {
        var TouchData       = new ArrayBuffer(16);
        var bufferWriter    = new DataView(TouchData);

        if(document.body.clientWidth * (Actual_Height/Actual_Width) <= window.innerHeight)
        {
            var ActualHeight = document.body.clientWidth * (Actual_Height/Actual_Width);
            var ActualWidth = document.body.clientWidth;

            var OffsetTop = element.offsetTop + (window.innerHeight - ActualHeight) / 2;
            var OffsetLeft = element.offsetLeft;
        }
        else
        {
            var ActualHeight = window.innerHeight;
            var ActualWidth = window.innerHeight * (Actual_Width/Actual_Height);

            var OffsetTop = element.offsetTop;
            var OffsetLeft = element.offsetLeft + (document.body.clientWidth - ActualWidth) / 2;
        }

        var Position1X       =   Math.max(0, Math.min(1, (Pos1X-OffsetLeft) / ActualWidth));
        var Position1Y       =   Math.max(0, Math.min(1, (Pos1Y-OffsetTop) / ActualHeight));
        var Position2X       =   Math.max(0, Math.min(1, (Pos2X-OffsetLeft) / ActualWidth));
        var Position2Y       =   Math.max(0, Math.min(1, (Pos2Y-OffsetTop) / ActualHeight));

        bufferWriter.setFloat32(0, Position1X, true);
        bufferWriter.setFloat32(4, Position1Y, true);
        bufferWriter.setFloat32(8, Position2X, true);
        bufferWriter.setFloat32(12, Position2Y, true);

        return TouchData;
    }

    //--------------------------------------------------------------------------
    static arrayBufferToString(Data)
    {
        var result = "";
        var string = new Uint8Array(Data);

        for (var i = 0; i < Data.byteLength; ++i)
            result += String.fromCharCode(string[i]);

        return result;
    }

    //--------------------------------------------------------------------------
    static matrixToTransform(matrix)
    {
        var position    = vec3.create();
        var orientation = quat.create();
        var scale       = vec3.create();

        mat4.getTranslation(position, matrix);
        mat4.getRotation(orientation, matrix);
        mat4.getScaling(scale, matrix);

        return {
            position    : Array.from(position),
            orientation : Array.from(orientation),
            scale       : Array.from(scale)
        };
    }

    //--------------------------------------------------------------------------
    static serializeUUID(uuid_str, bufferWriter, offset)
    {
        var uuid            = new UUID(uuid_str);
        var exportBuffer    = new Uint8Array(uuid.export());
        var exportView      = new DataView(exportBuffer.buffer);

        var data1 = exportView.getUint32(0, false);
        var data2 = exportView.getUint16(4, false);
        var data3 = exportView.getUint16(6, false);

        bufferWriter.setUint32(offset + 0, data1, true);
        bufferWriter.setUint16(offset + 4, data2, true);
        bufferWriter.setUint16(offset + 6, data3, true);

        for(var i = 8; i < 16; i++)
        {
            bufferWriter.setUint8(offset + i, exportView.getUint8(i), true);
        }
    }

    //--------------------------------------------------------------------------
    /**
     * @private
     *
     * Fill the entityTemplate object with the desired component and its attributes.
     * It also add others components required by the desired one.
     * @param {object} entityTemplate An empty object or already filled entity template object as the one returned by [Entity.getComponents()]{@link Entity#getComponents}
     * @param {string} newComponentClassName The class name of <a href="tutorial-components.html">the component</a> to add
     * @returns {object} The entityTemplate passed as parameter
     *
     * @method SDK3DVerse.utils#resolveComponentDependencies
     */
    static resolveComponentDependencies(entityTemplate, newComponentClassName)
    {
        const dependencies = SDK3DVerse.engineAPI.getComponentDescription(newComponentClassName).dependencies;
        if(dependencies)
        {
            for(const dependency of dependencies)
            {
                if(!entityTemplate.hasOwnProperty(dependency))
                {
                    SDK3DVerse_Utils.resolveComponentDependencies(entityTemplate, dependency);
                }
            }
        }
        entityTemplate[newComponentClassName] = SDK3DVerse.engineAPI.getComponentDefaultValue(newComponentClassName);
        return entityTemplate;
    }

    //--------------------------------------------------------------------------
    static getAssetFromEvent(event)
    {
        const assetData = event.dataTransfer.getData('3dverse/asset');
        if(!assetData)
        {
            return null;
        }

        const asset = JSON.parse(assetData);
        if(asset.isSourceFile)
        {
            if(!asset.mainAssetUUID)
            {
                return null;
            }

            asset.uuid = asset.mainAssetUUID;
        }

        return asset;
    }

    //--------------------------------------------------------------------------
    static preventIfEventContainsAsset(event)
    {
        const doesContains = event.dataTransfer.types.indexOf('3dverse/asset') != -1;
        if(doesContains)
        {
            event.preventDefault();
        }
        return doesContains;
    }

    //--------------------------------------------------------------------------
    static getComponentClassNameFromAssetLabel(assetLabel)
    {
        switch(assetLabel)
        {
            case "Scene":
                return "scene_ref";

            case "Mesh":
                return "mesh_ref";

            case "Material":
                return "material_ref";

            case "Shader":
                return "material";

            case "Texture3D":
                return "volume_ref";

            case "VolumeMaterial":
                return "volume_material_ref";

            case "Script":
                return "script_map";

            case "Skeleton":
                return "skeleton_ref";

            case "AnimationGraph":
                return "animation_controller";

            default:
                return undefined;
        };
    }

    //--------------------------------------------------------------------------
    static alignForYUV(width, height, upperAlignment)
    {
        if(!upperAlignment)
        {
            return {
                width   : width  - (width  % 16),
                height  : height - (height % 16)
            };
        }

        const offsetWidth   = width  % 16;
        const offsetHeight  = height % 16;

        return {
            width   : width  + (offsetWidth  ? (16 - offsetWidth)  : 0),
            height  : height + (offsetHeight ? (16 - offsetHeight) : 0)
        };
    }

    //--------------------------------------------------------------------------
    static uuidToUint8Array(uuidString)
    {
        var uuid        = new UUID(uuidString);
        var result      = new Uint8Array(uuid.export());
        var conversion  = new Uint8Array(result);

        var dataView_result		= new DataView(result.buffer);
        var dataView_conversion	= new DataView(conversion.buffer);

        var data1 = dataView_result.getUint32(0, false);
        var data2 = dataView_result.getUint16(4, false);
        var data3 = dataView_result.getUint16(6, false);

        dataView_conversion.setUint32(0, data1, true);
        dataView_conversion.setUint16(4, data2, true);
        dataView_conversion.setUint16(6, data3, true);

        return conversion;
    }

    //--------------------------------------------------------------------------
    static generateRTID(...args)
    {
        let buffer;

        if(args.length == 1)
        {
            buffer = SDK3DVerse_Utils.uuidToUint8Array(args[0]);
        }
        else
        {
            buffer              = new Uint8Array(8);
            const bufferWriter  = new DataView(buffer.buffer);
            this.writeRTID(args[0], bufferWriter, 0);
            this.writeRTID(args[1], bufferWriter, 4);
        }

        return XXH.h32().update(buffer.buffer).digest();
    }

    //--------------------------------------------------------------------------
    static readRTID(bufferReader, offset)
    {
        return bufferReader.getUint32(offset, true);
    }

    //--------------------------------------------------------------------------
    static writeRTID(rtid, bufferWriter, offset)
    {
        if(typeof rtid == 'number')
        {
            bufferWriter.setUint32(offset, rtid, true);
        }
        else
        {
            bufferWriter.setUint16(offset + 0, rtid._low, true);
            bufferWriter.setUint16(offset + 2, rtid._high, true);
        }
    }

    //--------------------------------------------------------------------------
    static getIdentityTransform()
    {
        return SDK3DVerse_Utils.patchTransform(
        {
            position    : [0.0, 0.0, 0.0],
            orientation : [0.0, 0.0, 0.0, 1.0],
            scale       : [1.0, 1.0, 1.0]
        });
    }

    //--------------------------------------------------------------------------
    static getXXH()
    {
        return XXH;
    }

    //--------------------------------------------------------------------------
    static appendByteArray(buffer1, buffer2)
    {
        let tmp = new Uint8Array((buffer1.byteLength|0) + (buffer2.byteLength|0));
        tmp.set(buffer1, 0);
        tmp.set(buffer2, buffer1.byteLength|0);
        return tmp;
    }

    //--------------------------------------------------------------------------
    static hexToRgb(hex)
    {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if(!result)
        {
            console.warn('Invalid hex color :', hex)
            return null;
        }
        return {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        };
    }

    //--------------------------------------------------------------------------
    static arraysEqual(a, b)
    {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i)
        {
            if (a[i] !== b[i]) return false;
        }

        return true;
    }

    //--------------------------------------------------------------------------
    static resolveDefaultValue(dictionnary, className)
    {
        const componentDescription = dictionnary[className];
        if(!componentDescription)
        {
            console.warn(`Unrecognized component : ${className}`);
            return;
        }

        const component = {};
        let match       = null;

        for(const attribute of componentDescription.attributes)
        {
            if(attribute.mods && attribute.mods.indexOf("engine-only") > -1)
            {
                continue;
            }

            if(attribute.default)
            {
                component[attribute.name] = attribute.default;
            }
            else if(attribute.type.startsWith("array"))
            {
                component[attribute.name] = [];
            }
            else if(attribute.type.startsWith("map<"))
            {
                component[attribute.name] = {};
            }
            else if(attribute.type == "string")
            {
                component[attribute.name] = "";
            }
            else if(attribute.type == "json")
            {
                component[attribute.name] = {};
            }
            else if(attribute.type == "entity_ref")
            {
                component[attribute.name] = {
                    originalEUID    : SDK3DVerse_Utils.invalidUUID,
                    linkage         : []
                }
            }
            else if(attribute.type == "uuid" || attribute.type.includes("_ref"))
            {
                component[attribute.name] = SDK3DVerse_Utils.invalidUUID;
            }
            else if(attribute.type == "bool")
            {
                component[attribute.name] = false;
            }
            else if(attribute.type == "float" || attribute.type.includes("int"))
            {
                component[attribute.name] = 0;
            }
            else if(match = attribute.type.match(/^i?vec([2-4])$/))
            {
                // Handle vec2, vec3, vec4, ivec2, ivec3 and ivec4
                const vectorSize            = parseInt(match[1]);
                component[attribute.name]   = Array.from(Array(vectorSize)).map(() => 0);
            }

            else if (match = attribute.type.match(/^mat([2-4])$/))
            {
                // Handle mat2, mat3 and mat4
                const matrixSize            = parseInt(match[1]);
                const elementCount          = matrixSize * matrixSize;
                component[attribute.name]   = Array.from(Array(elementCount)).map(() => 0);
                for(let i = 0; i < matrixSize; ++i)
                {
                    component[attribute.name][i * matrixSize + i] = 1;
                }
            }
            else
            {
                console.warn(`Unrecognized attribute type : ${attribute.type}`);
            }
        }

        return component;
    }

    //--------------------------------------------------------------------------
    static degreesToRadian(degrees)
    {
        return degrees * Math.PI / 180.0;
    }

    //--------------------------------------------------------------------------
    static radianToDegrees(radians)
    {
        return radians * 180.0 / Math.PI;
    }

    //--------------------------------------------------------------------------
    static copySign(a, b)
    {
        return b < 0 ? -Math.abs(a) : Math.abs(a);
    }

    //--------------------------------------------------------------------------
    static quaternionToEuler(quaternion)
    {
        const x         = quaternion[0];
        const y         = quaternion[1];
        const z         = quaternion[2];
        const w         = quaternion[3];

        const euler     = { roll : 0.0, pitch : 0.0, yaw: 0.0 };
        const q         = { x, y, z, w };

        // roll (x-axis rotation)
        let sinr_cosp   = +2.0 * (q.w * q.x + q.y * q.z);
        let cosr_cosp   = +1.0 - 2.0 * (q.x * q.x + q.y * q.y);
        euler.roll      = Math.atan2(sinr_cosp, cosr_cosp);

        // pitch (y-axis rotation)
        let sinp = +2.0 * (q.w * q.y - q.z * q.x);
        if (Math.abs(sinp) >= 1)
        {
            euler.pitch = SDK3DVerse_Utils.copySign(Math.PI / 2, sinp); // use 90 degrees if out of range
        }
        else
        {
            euler.pitch = Math.asin(sinp);
        }

        // yaw (z-axis rotation)
        let siny_cosp   = +2.0 * (q.w * q.z + q.x * q.y);
        let cosy_cosp   = +1.0 - 2.0 * (q.y * q.y + q.z * q.z);
        euler.yaw       = Math.atan2(siny_cosp, cosy_cosp);

        return [
            SDK3DVerse_Utils.radianToDegrees(euler.roll),
            SDK3DVerse_Utils.radianToDegrees(euler.pitch),
            SDK3DVerse_Utils.radianToDegrees(euler.yaw)
        ];
    }

    //--------------------------------------------------------------------------
    static quaternionFromEuler(eulers)
    {
        const [roll, pitch, yaw] = eulers.map(SDK3DVerse_Utils.degreesToRadian);

        const cy = Math.cos( yaw / 2 );
        const sy = Math.sin( yaw / 2 );
        const cp = Math.cos( pitch / 2 );
        const sp = Math.sin( pitch / 2 );
        const cr = Math.cos( roll / 2 );
        const sr = Math.sin( roll / 2 );

        return [
            cy * cp * sr - sy * sp * cr,
            sy * cp * sr + cy * sp * cr,
            sy * cp * cr - cy * sp * sr,
            cy * cp * cr + sy * sp * sr,
        ];
    }

    //--------------------------------------------------------------------------
    static patchTransform(transform, checkOrientation = true)
    {
        const hasQuat           = transform.hasOwnProperty("orientation");
        const hasEuler          = transform.hasOwnProperty("eulerOrientation");
        const patchedTransform  = { ...transform };

        if(hasQuat && !hasEuler)
        {
            patchedTransform.eulerOrientation = SDK3DVerse_Utils.quaternionToEuler(transform.orientation);
        }

        if(!hasQuat && hasEuler)
        {
            patchedTransform.orientation = SDK3DVerse_Utils.quaternionFromEuler(transform.eulerOrientation);
        }

        if(checkOrientation && (hasQuat || hasEuler))
        {
            const length = quat.length(patchedTransform.orientation);

            if(length < MinQuaternionLength || length > MaxQuaternionLength)
            {
                console.warn('Quaternion has a suspicious length, transformation could be corrupted.', length);
            }
        }

        return patchedTransform;
    }

    //--------------------------------------------------------------------------
    static computeAABB(localAABB, globalMatrix)
    {
        let center              = vec3.create();
        let longestAxisSize     = -Number.MAX_VALUE;

        const vertices = [
            vec3.fromValues(localAABB.min[0], localAABB.min[1], localAABB.min[2]),
            vec3.fromValues(localAABB.max[0], localAABB.min[1], localAABB.min[2]),
            vec3.fromValues(localAABB.min[0], localAABB.max[1], localAABB.min[2]),
            vec3.fromValues(localAABB.min[0], localAABB.min[1], localAABB.max[2]),
            vec3.fromValues(localAABB.max[0], localAABB.max[1], localAABB.max[2]),
            vec3.fromValues(localAABB.min[0], localAABB.max[1], localAABB.max[2]),
            vec3.fromValues(localAABB.max[0], localAABB.min[1], localAABB.max[2]),
            vec3.fromValues(localAABB.max[0], localAABB.max[1], localAABB.min[2])
        ];

        const minVertex = vec3.fromValues(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
        const maxVertex = vec3.fromValues(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

        if(globalMatrix)
        {
            for(const vec of vertices)
            {
                vec3.transformMat4(vec, vec, globalMatrix);
            }
        }

        for(const vec of vertices)
        {
            vec3.min(minVertex, vec, minVertex);
            vec3.max(maxVertex, vec, maxVertex);
            vec3.add(center, vec, center);
        }

        vec3.scale(center, center, 1/8);

        const axes = [
            {vertexIndex: 0, testIndex : [1, 2, 3]},
            {vertexIndex: 6, testIndex : [1, 3, 4]},
            {vertexIndex: 5, testIndex : [2, 3, 4]},
            {vertexIndex: 7, testIndex : [1, 2, 4]}
        ];

        for(const axis of axes)
        {
            const a = vertices[axis.vertexIndex];

            for(const index of axis.testIndex)
            {
                const b = vertices[index];
                const d = vec3.distance(a, b);

                if(d > longestAxisSize)
                {
                    longestAxisSize = d;
                }
            }
        }

        return {
            min         : Array.from(minVertex),
            max         : Array.from(maxVertex),
            center      : Array.from(center),
            longestAxisSize
        };
    }

    //--------------------------------------------------------------------------
    static getDiagonalFromAABB(aabb)
    {
        const l = Math.abs(aabb.max[0] - aabb.min[0]);
        const h = Math.abs(aabb.max[1] - aabb.min[1]);
        const p = Math.abs(aabb.max[2] - aabb.min[2]);
        return Math.sqrt(l*l + h*h + p*p);
    }

    //--------------------------------------------------------------------------
    static lookAtAABB(aabb, cameraPosition, fov, distanceShift = 0)
    {
        const globalPosition    = vec3.fromValues(...aabb.center);

        let direction           = vec3.create();
        vec3.sub(direction, cameraPosition, globalPosition);
        vec3.normalize(direction, direction);

        const distance          = distanceShift + (aabb.longestAxisSize/2) / Math.tan(glMatrix.toRadian(fov)/2) * 1.2;

        let targetPosition      = vec3.create();
        vec3.scaleAndAdd(targetPosition, globalPosition, direction, distance);

        let rightVector         = vec3.create();
        vec3.cross(rightVector, direction, SDK3DVerse_Utils.neutralUp);

        let upVector            = vec3.create();
        vec3.cross(upVector, rightVector, direction);

        let targetToMat         = mat4.create();
        mat4.targetTo(targetToMat, targetPosition, globalPosition, upVector);

        let targetOrientation   = quat.create();
        mat4.getRotation(targetOrientation, targetToMat);

        return {
            targetPosition      : Array.from(targetPosition),
            targetOrientation   : Array.from(targetOrientation)
        };
    }

    //--------------------------------------------------------------------------
    static isAABBValid(aabb)
    {
        const infiniteThreshold = Number.MAX_SAFE_INTEGER;

        return aabb.min.every(v => isFinite(v) && Math.abs(v) < infiniteThreshold)
            && aabb.max.every(v => isFinite(v) && Math.abs(v) < infiniteThreshold);
    }
}
